<template>
  <div class="new-cases-price__wrapper">
    <div class="new-cases-price__inner">
      <div class="new-cases-price__main-price">
        2 099 000 <span>{{ $t("sum") }}</span>
      </div>
      <!-- <div class="new-cases-price__prev-price">
        <div class="new-cases-price__prev-price-text">
          1 970 000 <span>{{ $t("sum") }}</span>
        </div>
        <div class="new-cases-price__card-container">
          <div class="new-cases-price__card">-10%</div>
          <div class="new-cases-price__card">{{ $t("skidka") }}</div>
        </div>
      </div> -->
    </div>
    <div class="new-cases-price__btn-container"><Btn></Btn></div>
  </div>
</template>

<script>
import Btn from "../BtnWrapper/Btn/Btn.vue";

export default {
  components: { Btn },
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style></style>
